export default {
    name: 'home',
    component : ()=>import(/* webpackChunkName: "auth" */'@/modules/home/layouts/HomeLayout.vue'),
    children: [
       {
           path: '',
           name: 'indexPage',
           component: ()=>import(/* webpackChunkName: "indexPage" */'@/modules/home/views/IndexPage.vue'),
       },
       {
           path: 'history',
           name: 'historyPage',
           component: ()=>import(/* webpackChunkName: "historyPage" */'@/modules/home/views/HistoryPage.vue'),
       },
       {
           path: 'enruta/:id',
           name: 'enruta',
           component: ()=>import(/* webpackChunkName: "enruta" */'@/modules/home/views/EnrutaPage.vue'),
       },       
       {
           path: 'ensitio/:id',
           name: 'ensitio',
           component: ()=>import(/* webpackChunkName: "ensitio" */'@/modules/home/views/EnsitioPage.vue'),
       },
    ]
}