import axios from "axios"
import env from '@/environment'

const url = env.APISERVICE;
const authApi = axios.create({
    baseURL: `${url}/apiDelmond`,
    
})


export default authApi