
export const newOrders =  (state, newOrders)=>{
    state.newOrders = newOrders;
}
export const getOrdersInProcess =  (state, getOrdersInProcess)=>{
    state.getOrdersInProcess = getOrdersInProcess;
}
export const getOrderEnRuta =  (state, getOrderEnRuta)=>{
    state.getOrderEnRuta = getOrderEnRuta;
}

export const setPublicKey =  (state, key)=>{
    state.publicKeyPush = key;
}

export const setCoord =  (state, coord)=>{
    state.coord = coord;
}

// export const logout =  (state)=>{
   
//  }
