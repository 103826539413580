export const getNewOrders =  (state)=>{
    return state.newOrders;

}

export const getOrdersInProcess =  (state)=>{
    return state.getOrdersInProcess;

}
export const getOrderEnRuta =  (state)=>{
    return state.getOrderEnRuta;
}

export const getCoord =  (state)=>{
    return state.coord || '';
}

