import env from '@/environment'
export const currentState =  (state)=>{
    return state.status;

}
export const currentName = (state)=>{
     return state.user?.name ||'';
}
export const currentDeliveryId = (state)=>{
     return state.user?.id || '';
}
export const currentSignature = (state)=>{
     
     return state.user?.signature ||'';
}
export const currentImage = (state)=>{
     return env.URLIMAGE+state.user?.image ||'';
}
export const currentUser = (state)=>{
     return state.user?.id;
}
export const currentIdToken = (state)=>{
     return state.idToken;
}
