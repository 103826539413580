import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AuthRouter from '@/modules/auth/router'
import HomeRouter from '@/modules/home/router'
import isAuthenticatedGuard from "@/modules/auth/guard/auth-guard";

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: "login" },
    component: HomeView
  },
  {
    path: "/auth",
    ...AuthRouter,
  },
  {
    path: "/home",
    beforeEnter: [isAuthenticatedGuard],
    ...HomeRouter,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "login" },
    component: () =>
      import(
        /*webpackChunkName="NoPageFound"*/ "@/views/NoPageFound.vue"
      ),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
