import { createStore } from 'vuex';
import auth from '@/modules/auth/store';
import ui from './ui';
import home from '@/modules/home/store';

export default createStore({
modules:{
  auth,
  ui,
  home



}
})
