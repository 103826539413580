
export const toggleSideMenu = (state) => {
  state.isSideMenuOpen = !state.isSideMenuOpen

  localStorage.setItem("toggleBar", state.isSideMenuOpen)
}
export const changeStatusMenu = (state ) => {
  state.isSideMenuOpen = localStorage.getItem("toggleBar") === "false" ? false : true;
}


