import delmondApi from "@/api/delmondApi"
import env from '../../../environment';

export const getNewOrders = async ({  commit }, deliveryId ) => {
  
    const {data} = await delmondApi.get(`delivery/getNewOrder/${deliveryId}`)
    commit('newOrders', data);
}
export const getOrdersInProcess = async ({  commit }, deliveryId ) => {
  
    const {data} = await delmondApi.get(`delivery/getOrdersInProcess/${deliveryId}`)
    commit('getOrdersInProcess', data);
}
export const getOrderEnRuta = async ({  commit }, orderId ) => {
  
    const {data} = await delmondApi.get(`delivery/getOrderEnRuta/${orderId}`)
    commit('getOrderEnRuta', data);
    return data;
}
export const changeStatus = async ({  commit }, service ) => {
    try {
        const {data} = await delmondApi.post(`delivery/changeStatus`,service)
        if(data.affectedRows>0) return true
        return false;
    } catch (error) {
        console.log(error);
        throw error;
    }
 }
 export const updateService = async ({  commit }, service ) => {
    try {
        const {data} = await delmondApi.post(`delivery/updateService`,service)
        if(data.affectedRows>0) return true
        return false;
    } catch (error) {
        console.log(error);
        throw error;
    }
 }

 export const uploadImages = async (formData, order_id, order_status, id) => {
    console.log(order_id, order_status)
    try {
        const { data } = await delmondApi.post('delivery/upload', formData, {
            params: { order_id, order_status, id } 
        });
       
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};
 export const getPublicKeyPush = async( ) => {
  
    return fetch(`${env.APISERVICE}/apiDelmond/push/key`)
        .then(res => res.arrayBuffer())
        .then(key => new Uint8Array(key));    
    }

export const setSubscribe = async({  commit }, subs)=>{

    const {data} = await delmondApi.post(`push/subscribe`,subs)
    return data.affectedRows;
}

export const setCoord = async({  commit }, coord)=>{

    const {data} = await delmondApi.post(`push/setCoord`,coord)

    commit('setCoord', coord);
}

// getPublicKeyPush().then(console.log)

